@import "../colors/colors";

.navmenu-container {
    height: 75px;
    border-top: 1px solid $secondary-argent;
    border-bottom: 1px solid $secondary-argent;
}

.list-item {
    text-transform: uppercase;
    text-decoration: none;
    height: 16px;
    color: $secondary-quartz;
    margin-right: 20px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.53px;
    line-height: 16px;
    text-align: center;
    &:hover {
        color: $secondary-caribbean-green !important;
        border-bottom: 2px solid $secondary-caribbean-green !important;
        transition: 0.05s ease-in;
    }
}
.list-item-small{
    text-transform: uppercase;
    text-decoration: none;
    height: 16px;
    color: $secondary-quartz;
    margin-right: 8px;
    padding: 9px 8px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.53px;
    line-height: 16px;
    text-align: center;
    &:hover {
        color: $secondary-caribbean-green !important;
        border-bottom: 2px solid $secondary-caribbean-green !important;
        transition: 0.05s ease-in;
    }
}
.nav-link {
    color: $secondary-caribbean-green !important;
    border-bottom: 2px solid $secondary-caribbean-green !important;
}
.dropdown-category {
    height: 58px; 
    padding-left: 35px !important;
    border-bottom: 1px solid $background-container-dark;
}
.category-title {
    height: 16px;
    overflow: hidden;
    color: $secondary-quartz;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.53px;
    line-height: 16px;
    text-align: center;
}
.cat-texas{
    width: 250px;
}
.cat-normal{
    width: 132px;
}
.navmenu-sidebar-icon-badge {
    height: 20px;
    width: 20px;
    background-color: $secondary-chinese-silver; //$secondary-quartz for active count
    position: relative;
    left: -3px;
    border-radius: 50%;
    float: right;
}


.report-sidebar-icon-badge-count-light{  
height: 17px;
  width: 17px;
  color: $secondary-white;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  margin: auto;
  line-height: 17px;
  position: relative;
  left: -11px;
  bottom: 4px;
}
.sticky{
    position: sticky;
    top: 0px;
    background: $secondary-white;
    z-index: 8;
}
.nav-right{
    align-items: center;
    justify-content: end;
    gap: 40px;
    padding-right: 55px;
    span{
        font-size: 14px;
        font-weight: 600;
        color: $primary-black-olive;
        display: flex;
        gap: 8px;
        cursor: pointer;
    }
}
.nav-right-responsive{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .print_elem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        align-items: center;
        padding-right: 20px;
        padding-top: 8px;
        span{
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
        font-weight: 600;
        color: $primary-black-olive;
        }
    }
}
@media screen and (min-width: 100px) and (max-width: 330px) {
.list-item-small{
    padding: 4px 2px;
}
}