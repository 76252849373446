// this are primary colors :

$primary-guppie-green: #11ce94;
$primary-black-olive: #3b3b3b;
$primary-read-more: #079171;
// this are secondary colors :

$secondary-sonic-silver: #777777;
$secondary-light-silver: #d8d8d8;
$secondary-white: #ffffff;
$secondary-caribbean-green: #0ead7c;

// this are severity ratings
$severity-acceptable: #0f8cff;
$severity-marginal: #6977df;
$severity-defective: #26308e;
$severity-defecient: #26308e;
$severity-not-inspected: #71b8f0;
$severity-not-present: #678aab;
$severity-extra-1: #469fae;
$severity-extra-2: #a443ad;
$severity-extra-3: #20c0ff;
$severity-extra-4: #4adcbe;
$severity-extra-5: #219f8c;

// this are extra essential colors
$secondary-argent: #C1C1C1;
$secondary-chinese-silver: #CCCCCC;
$secondary-chinese-white: #E0E0E0;
$secondary-spanish-grey: #979797;
$secondary-black-olive: #3b3b3b;
$secondary-old-silver: #868686;
$secondary-quartz: #4a4a4a;
$secondary-sonic-silver-1: #757575;
$forgot-password-color:#9B9B9B;

// this are container background color
$background-container: rgba(22, 22, 22, 0.6);
$background-container-dark: rgba(0, 0, 0, 0.8);
$menu-icon-background:#FAFAFA
